import React, {useState} from "react";
import CustomTooltip from "./CustomTooltip";
import {Button, Dialog, DialogContent} from "@mui/material";

const QrCodeComponent = ({qrCodeUrl}) => {
    const [open, setOpen] = useState(false)
    function handleToggleDialog() {
        setOpen(!open)
    }

    return (
        <>
            <CustomTooltip title={'Apri QR code'} children={
                <Button //href={params.row.qrCodeUrl}
                    //target={'_blank'}
                    size={'small'}
                    onClick={(event) => {
                        event.stopPropagation()
                        handleToggleDialog()
                    }}>
                    <img src={qrCodeUrl} style={{maxWidth: '30px'}} onClick={() => {}}/>
                </Button>
            }/>
            {/*<CustomTooltip title={"Copia link al QR"} children={
                <IconButton
                    onClick={async (event) => {
                        event.stopPropagation()
                        await navigator.clipboard.writeText(params.row.qrCodeUrl)
                    }}>
                    <ContentCopy fontSize={'small'}/>
                </IconButton>
            }/>*/}
            <Dialog open={open} onClose={handleToggleDialog}>
                <DialogContent>
                    <img src={qrCodeUrl} style={{width: '200px'}}/>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default QrCodeComponent